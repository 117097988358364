.pagination {
    padding: rem(67) 0 rem(85);
	@include flex-center;

	// .pagination__list

	&__list {
		display: flex;
	}

	// .pagination__link

	&__link {
        @include flex-center;
        width: rem(36);
        height: rem(36);
        transition: var(--transition) background-color, var(--transition) color;

		&.active {
            background-color: var(--black);
            color: var(--white);
        }

		&:not(.active) {
            @media (any-hover: hover) {
                &:hover {
                    color: var(--accent);
                }
            }
        }
	}

	&__prev,
	&__next {
        @include flex-center;
        width: rem(50);
        height: rem(50);

        svg {
            width: rem(33);
            height: rem(33);
            fill: var(--black);
            transition: var(--transition) fill;
        }

		@media (any-hover: hover) {
			&:hover {
                svg {
                    fill: var(--accent);
                }
            }
		}
	}
}
