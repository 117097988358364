.form {
	&__agree,
	&__required {
		@include font-size(14, 17);

		@include media('500', max) {
			font-size: rem(11);
		}
	}
}

.feedback-form {
    $this: &;

	display: grid;
	gap: rem(20);

	@include media('768', min) {
		grid-template-columns: 1fr auto;
		grid-template-areas:
			'fields fields'
			'agree submit'
			'required submit'
		;
	}

	&__fields {
		display: grid;
		gap: rem(16) rem(28);

		@include media('768', min) {
			grid-template-columns: repeat(2, 1fr);
			grid-area: fields;
		}
	}

	&__agree {
		@include media('768', min) {
			grid-area: agree;
		}
	}

	// .feedback-form__required

	&__required {
		@include media('768', min) {
			grid-area: required;
		}
	}

	&__btn {
		padding: rem(11.5) rem(44.25);
        @include font-size(18, 27);

		@include media('768', min) {
			grid-area: submit;
			align-self: start;
		}
	}

    &--orientation {
        &_column {
            @include media('768', min) {
                grid-template-columns: unset;
                grid-template-areas: unset;
            }

            #{$this} {
                &__fields {
                    @include media('768', min) {
                        grid-template-columns: unset;
                        grid-area: unset;
                    }
                }

                &__required {
                    @include media('768', min) {
                        grid-area: unset;
                    }
                }

                &__btn {
                    @include padding-x(rem(29));
                    justify-self: center;
                }

                &__fields,
                &__required,
                &__agree,
                &__btn {
                    @include media('768', min) {
                        grid-area: unset;
                    }
                }
            }
        }
    }
}
