.title {
	@include font-size(30, 40);
	font-weight: 600;

	@include media('767', max) {
		font-size: rem(20);
	}
}

h1,
h2,
h3,
h4 {
	font-weight: 600;
}

h1 {
	@include font-size(30, 40);

    @include media('500', max) {
        font-size: rem(24);
    }
}

h2 {
	@include font-size(24, 29);

    @include media('500', max) {
        font-size: rem(20);
    }
}

h3,
h4 {
	@include font-size(20, 24);
    
    @include media('500', max) {
        font-size: rem(16);
    }
}