.resume-block {
    position: relative;
    padding: rem(44) rem(62) rem(22);
    @include flex-column;
    background-color: var(--white);
    box-shadow: var(--bsh);

    @include media('767', max) {
        padding: rem(30) rem(40);
    }

    @include media('1201', min) {
        max-width: rem(1114);
        margin: 0 auto;
    }

    &::before {
        content: '';
        position: absolute;
        top: rem(58);
        left: rem(-32);
        width: rem(61);
        height: rem(61);
        background-image: url(../images/icons/clip.svg);
        @include bg(contain);
    }

    &__title {
        margin-bottom: rem(17);
        @include font-size(24, 29);
        font-weight: 600;

        @include media('500', max) {
            font-size: rem(20);
        }
    }

    &__email {
        margin-bottom: rem(37);
        @include font-size(24, 29);
        font-weight: 600;
        text-transform: uppercase;

        @include media('500', max) {
            margin-bottom: rem(20);
            font-size: rem(20);
        }

        @media (any-hover: hover) {
            &:hover {
                color: var(--accent);
            }
        }
    }

    &__subtitle {
        @include font-size(20, 24);
        font-weight: 600;

        @include media('500', max) {
            font-size: rem(16);
        }
    }

    &__text {
        @include font-size(20, 30);

        @include media('500', max) {
            font-size: rem(16);
        }
    }
}
