@mixin grid($cols, $row-gap, $col-gap) {
	display: grid;
	grid-template-columns: repeat($cols, 1fr);
	gap: $row-gap $col-gap;
}

@mixin grid-auto-fit($baseWidth, $gap) {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax($baseWidth, 1fr));
	gap: $gap;
}
