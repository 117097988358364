.title-container {
	&--with {
		&_bg {
			@include padding-y(rem(20));
			background-color: var(--gray);
		}
	}

    & + .text-block {
        padding-bottom: rem(50);

        @include media('768', min) {
            @include font-size(20, 30);
        }
    }
}

.page-top {
    padding-bottom: rem(25);
}
