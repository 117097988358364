$breakpoints: (
    "500": 500px,
	"501": 501px,
	"767": 767px,
	"768": 768px,
	"1024": 1024px,
	"1025": 1025px,
	"1200": 1200px,
	"1201": 1201px,
	"1366": 1366px,
);

@mixin media($breakpoint, $type) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (#{$type}-width: $breakpoint-value) {
			@content;
		}
	}

	@else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}
