.vacancy-block {
    padding: rem(8) 0 rem(57);
    background-color: var(--gray-3);

    &__heading {
        @include padding-y(rem(18));

        @include media('1025', min) {
            @include padding-x(rem(45));
        }
    }

    &__title {
        position: relative;
        display: inline-block;
        padding-right: rem(24);
        @include font-size(20, 24);
        font-weight: 400;
        color: var(--gray-2);

        &::after {
            content: '';
            @include position-center-y;
            right: rem(-138);
            width: rem(138);
            height: 1px;
            background-color: var(--gray);
        }
    }
}

.vacancies-list {
    padding-bottom: rem(74);
}