.footer {
	// .footer__top

	&__top {
		padding: rem(16) 0 rem(6);
		background-color: var(--dark);
		color: var(--white);

		@include media('1024', max) {
			padding-bottom: rem(16);
		}
	}

	&__top-inner {
		display: grid;
		gap: rem(16);

		@include media('1025', min) {
			align-items: center;
			grid-template-columns: calc(282 / var(--container-width) * 100%) 1fr;
			grid-template-areas:
				'logo menu'
			;
		}
	}

	&__logo {
		.logo__img {
			display: block;
			width: rem(239);
			height: rem(100);
		}

		@include media('1025', min) {
			grid-area: logo;
		}
	}

	&__menu {
		@include media('1025', min) {
			grid-area: menu;
		}
	}

	// .footer__main

	&__main {
		padding: rem(22) 0 rem(26);
		background-color: var(--gray);
	}

	// .footer__main-inner

	&__main-inner {
		display: grid;
		gap: rem(16) calc(62 / 1200 * 100%);

		@include media('501', min) {
			grid-template-columns: repeat(2, 1fr);
			column-gap: rem(30);
		}

		@include media('1025', min) {
			column-gap: calc(62 / 1200 * 100%);
			grid-template-columns: repeat(2, 1fr) repeat(2, minmax(calc(225 / 1200 * 100%), auto));
		}
	}

	&__main-inner {
		@include media('501', min) {
			grid-template-areas:
				'contacts call'
				'menu-1 menu-2'
				'copy developer'
			;
		}

		@include media('1025', min) {
			grid-template-areas:
				'contacts call menu-1 menu-2'
				'copy . . developer'
			;
		}
	}

	// .footer__col

	&__col {
		@include flex-column-start;

		&--contacts {
			@include media('501', min) {
				grid-area: contacts;
			}
		}

		&--call {
			@include media('501', min) {
				grid-area: call;
			}
		}
	}

	// .footer__title

	&__title {
		margin-bottom: rem(8);
		font-weight: 600;
	}

	&__title,
	&__link {
		@include font-size(14, 31);
	}

	&__address {
		@include media('768', min) {
			max-width: rem(256);
		}
	}

	// .footer__address

	&__address,
	&__schedule,
	&__email,
	&__contact {
		@include font-size(14, 17);
	}

	// .footer__schedule

	&__schedule {
		margin-bottom: rem(3);

		>div {
			display: flex;
			gap: 2px;
		}

		dt {
			font-weight: 500;
		}
	}

	// .footer__contacts

	&__contacts {
		@include row-grid(rem(8));
		margin-bottom: rem(22);
	}

    &__contact {
        @include flex-center-y;
        gap: rem(7);
    }

	&__messanger {
		width: rem(17);
		height: rem(17);
	}

	&__btn {
		padding: rem(11.5) rem(18);
	}

	// .footer__menu

	&__menu {

		&-1 {
			@include media('501', min) {
				grid-area: menu-1;
			}
		}

		&-2 {
			@include media('501', min) {
				grid-area: menu-2;
			}
		}
	}

	// .footer__copyright

	&__copyright {
		@include font-size(14, 25);

		@include media('501', min) {
			grid-area: copy;
		}
	}

	// .footer__developer

	&__developer {
		@include flex-center-y;

		@include media('501', min) {
			grid-area: developer;
		}
	}

	// .footer__developer-logo

	&__developer-logo {
		flex-shrink: 0;
		margin-right: rem(5);
		width: rem(15);
		height: rem(15);
		background-color: #8ABE2E;
	}

	// .footer__developer-text

	&__developer-text {
		@include font-size(12, 20);

		a {
			text-decoration: underline;
			transition: var(--transition) color;

			@media (any-hover: hover) {
				&:hover {
					color: var(--accent);
				}
			}
		}
	}
}
