.feedback {
	padding: rem(43) 0 rem(83);
	min-height: rem(403);
	background-color: var(--black);
	color: var(--white);
	background-image: url(../images/content/form-bg.jpg);
	@include bg(cover);

	@include media('767', max) {
		@include padding-y(rem(30));
	}

	// .feedback__form

	&__form {
		@include media('1025', min) {
			width: rem(725);
			margin: 0 auto;
		}
	}

	&__title {
		text-align: center;
		margin-bottom: rem(42);

		@include media('767', max) {
			margin-bottom: rem(25);
		}
	}
}
