@charset "UTF-8";
* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
*:before, *:after {
  box-sizing: inherit;
}

audio,
canvas,
progress,
video {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

template {
  display: none;
}

html {
  font-family: sans-serif;
  word-break: break-word;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  cursor: default;
}

body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*:focus,
*:active,
*:hover {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  white-space: pre-wrap;
  tab-size: 4;
}

q {
  quotes: "«" "»";
}

button,
input {
  line-height: normal;
}

button,
input,
select {
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  vertical-align: top;
}

button,
input,
select[multiple],
textarea {
  background-image: none;
}

input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}

input,
textarea {
  resize: none;
  user-select: text;
}

button,
textarea,
select,
input[type=text],
input[type=password],
input[type=search],
input[type=submit],
input[type=button],
input[type=reset],
input[type=number],
input[type=range] {
  border-radius: 0;
  appearance: none;
}
button::-ms-clear, button::-ms-reveal,
textarea::-ms-clear,
textarea::-ms-reveal,
select::-ms-clear,
select::-ms-reveal,
input[type=text]::-ms-clear,
input[type=text]::-ms-reveal,
input[type=password]::-ms-clear,
input[type=password]::-ms-reveal,
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal,
input[type=submit]::-ms-clear,
input[type=submit]::-ms-reveal,
input[type=button]::-ms-clear,
input[type=button]::-ms-reveal,
input[type=reset]::-ms-clear,
input[type=reset]::-ms-reveal,
input[type=number]::-ms-clear,
input[type=number]::-ms-reveal,
input[type=range]::-ms-clear,
input[type=range]::-ms-reveal {
  display: none;
}

[placeholder]:focus::placeholder {
  color: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

iframe {
  border: none;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dd,
dt {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}
table img {
  max-width: none;
}

address {
  font-style: normal;
}

small {
  font-size: inherit;
}

.no-js-warning {
  position: fixed;
  z-index: 99999;
  width: 100%;
  padding: 15px 20px;
  color: #f00;
  font-size: 20px;
  background: rgba(255, 0, 0, 0.5);
}

:root {
  --white: #fff;
  --black: #000;
  --accent: #E0A100;
  --dark: #272425;
  --gray: #D9D9D9;
  --gray-2: #A0A0A0;
  --gray-3: #F5F5F5;
  --gray-4: #757575;
  --container-width: 71.25rem;
  --container-width_xl: 75rem;
  --container-padding: 0.9375rem;
  --base-font-size: 16;
  --main-font: "Montserrat", sans-serif;
  --inter-font: "Inter", sans-serif;
  --barlow-font: "Barlow", sans-serif;
  --color-default: var(--black);
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  --duration300ms: 0.3s;
  --duration500ms: 0.5s;
  --duration1s: 1s;
  --delay: var(--duration300ms);
  --transition: var(--duration300ms) var(--transition-timing);
  --transition-long: var(--duration1s) var(--transition-timing);
  --brs: 25px;
  --brs-xs: 3px;
  --bsh: 0 4px 15px rgba(0, 0, 0, 0.15);
  --bsh-2: 0 4px 25px rgba(0, 0, 0, 0.1);
}

html {
  box-sizing: border-box;
  /* scrollbar-color: rgb(var(--white), 0.3) transparent;
     scrollbar-width: thin;
     scroll-behavior: smooth;

     &::-webkit-scrollbar {
     	width: rem(12);
     	height: rem(12);
     	background-color: transparent;
     }

     &::-webkit-scrollbar-thumb {
     	background-color: rgb(var(--white), 0.3);
     	border-radius: rem(10);
     } */
}
@media (min-width: 1025px) {
  html.disable-fix {
    padding-right: 17px;
  }
}

body {
  min-width: 320px;
  font-style: normal;
  font-family: var(--main-font);
  font-size: calc(var(--base-font-size) / 10 * 1rem);
  line-height: 1.5;
  font-weight: 400;
  color: var(--color-default);
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  overflow-x: hidden;
}
body._lock {
  overflow-y: hidden;
}

a {
  text-decoration: none;
  color: currentColor;
}

p {
  color: var(--gray-2);
}

.text-content {
  padding-bottom: 3.75rem;
}
@media (max-width: 767px) {
  .text-content {
    padding-bottom: 1.875rem;
  }
}
.text-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--black);
}
.text-content strong {
  color: var(--accent);
  font-weight: 400;
}
.text-content ul {
  margin-left: 1.25rem;
  list-style: disc;
  font-size: 1.25rem;
  line-height: 1.7;
}
@media (max-width: 767px) {
  .text-content ul {
    font-size: 1rem;
  }
}
.text-content h2,
.text-content h3,
.text-content p,
.text-content ul,
.text-content ol {
  margin-bottom: 1.875rem;
}
@media (max-width: 500px) {
  .text-content h2,
  .text-content h3,
  .text-content p,
  .text-content ul,
  .text-content ol {
    margin-bottom: 1.25rem;
  }
}
.text-content > picture + *,
.text-content .table-wrap + * {
  margin-top: 1.5625rem;
}
.text-content aside {
  padding-left: 1.25rem;
  border-left: 3px solid var(--black);
  font-size: 0.875rem;
  line-height: 1.5;
}
.text-content blockquote {
  margin-bottom: 1.875rem;
  position: relative;
  display: inline;
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (max-width: 1200px) {
  .text-content blockquote {
    padding-left: 1.875rem;
  }
}
@media (max-width: 767px) {
  .text-content blockquote {
    font-size: 1rem;
  }
}
.text-content blockquote::before, .text-content blockquote::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(../images/icons/quotes.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.text-content blockquote::before {
  top: 0;
  left: -1.8125rem;
}
@media (max-width: 1200px) {
  .text-content blockquote::before {
    left: 0;
  }
}
.text-content blockquote::after {
  right: -1.8125rem;
  bottom: -0.25rem;
}
.text-content blockquote + * {
  margin-top: 1.875rem;
}
@media (max-width: 500px) {
  .text-content blockquote + * {
    margin-top: 0.9375rem;
  }
}
.text-content table {
  width: 100%;
}
.text-content td {
  padding: 1.0625rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.625;
  width: 25%;
  white-space: nowrap;
}
@media (max-width: 500px) {
  .text-content td {
    font-size: 0.875rem;
  }
}
@media (min-width: 1025px) {
  .text-content td:first-child {
    padding-left: 4.5rem;
  }
}
.text-content td a {
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .text-content td a:hover {
    color: var(--accent);
  }
}
.text-content thead {
  background-color: var(--black);
  color: var(--white);
}
.text-content tbody td {
  border-bottom: 1px solid var(--gray);
}
.text-content--transport table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.text-content--transport table td {
  white-space: unset;
}
@media (max-width: 767px) {
  .text-content--transport table td {
    width: auto;
  }
}
@media (min-width: 1025px) {
  .text-content--transport table td:first-child {
    padding-left: 1.875rem;
  }
}
@media (max-width: 767px) {
  .text-content--transport table td:first-child {
    min-width: 15.625rem;
  }
}
.text-content--transport table td:nth-child(2) {
  white-space: nowrap;
}
@media (min-width: 1025px) {
  .text-content--transport table td:nth-child(2) {
    width: 13.1%;
  }
}
@media (min-width: 1025px) {
  .text-content--transport table td:last-child {
    width: 23.4%;
  }
}
@media (max-width: 767px) {
  .text-content--transport table td:last-child {
    min-width: 17.1875rem;
  }
}
@media (min-width: 1025px) {
  .text-content--transport table td {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
.text-content--transport table tbody td {
  border-top: 1px solid var(--black);
  border-bottom-color: var(--black);
  border-right: 1px solid var(--black);
}
.text-content--transport table tbody td:first-child {
  border-left: 1px solid var(--black);
}

.text-block {
  font-size: 1rem;
  line-height: 1.1875;
}

@media (max-width: 1024px) {
  .table-wrap {
    overflow-x: auto;
  }
}

.container {
  --width: var(--container-width);
  width: calc(var(--width) + var(--container-padding) * 2);
  max-width: 100%;
  padding-right: var(--container-padding);
  padding-left: var(--container-padding);
  margin: 0 auto;
}
.container--fluid {
  --width: var(--container-width_xl);
}
.container > .text-block:last-child {
  padding-bottom: 2.8125rem;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.page {
  position: relative;
  flex: 1 0 auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.full-link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.btn {
  padding: 0;
  cursor: pointer;
  border: none;
  font-family: var(--main-font);
  transition: var(--transition) color, var(--transition) background-color, var(--transition) box-shadow;
  color: currentColor;
}
.btn.disabled, .btn[disabled] {
  background-color: var(--gray);
  border: none;
  cursor: not-allowed;
}
@media (any-hover: hover) {
  .btn.disabled:hover, .btn[disabled]:hover {
    color: var(--white);
  }
}
.btn svg,
.btn .icon, .btn__icon {
  flex-shrink: 0;
  transition: var(--transition) fill;
}
.btn--style_bg-black, .btn--style_bg-accent {
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: var(--brs);
  color: var(--white);
}
.btn--style_bg-black {
  background-color: var(--black);
}
@media (any-hover: hover) {
  .btn--style_bg-black:hover {
    background-color: var(--accent);
  }
}
.btn--style_bg-accent {
  background-color: var(--accent);
}
@media (any-hover: hover) {
  .btn--style_bg-accent:hover {
    background-color: var(--white);
    color: var(--black);
  }
}
.btn--style_messanger {
  display: inline-block;
  border-radius: 50%;
  transition: var(--transition) transform;
}
@media (any-hover: hover) {
  .btn--style_messanger:hover {
    transform: translateY(-3px);
  }
}
@media (any-hover: hover) {
  .btn--style_link:hover {
    color: var(--accent);
  }
}
.btn--transparent {
  background-color: transparent;
}
@media (any-hover: hover) {
  .btn--transparent:hover {
    background-color: transparent;
  }
}

.title {
  font-size: 1.875rem;
  line-height: 1.3333333333;
  font-weight: 600;
}
@media (max-width: 767px) {
  .title {
    font-size: 1.25rem;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

h1 {
  font-size: 1.875rem;
  line-height: 1.3333333333;
}
@media (max-width: 500px) {
  h1 {
    font-size: 1.5rem;
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 1.2083333333;
}
@media (max-width: 500px) {
  h2 {
    font-size: 1.25rem;
  }
}

h3,
h4 {
  font-size: 1.25rem;
  line-height: 1.2;
}
@media (max-width: 500px) {
  h3,
  h4 {
    font-size: 1rem;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  border: 1px solid #B2BBC0;
  background-color: transparent;
  opacity: 1;
  transition: var(--transition) background-color, var(--transition) border-color;
}
@media (any-hover: hover) {
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:hover,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:hover {
    background-color: #B2BBC0;
  }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: var(--black);
  border-color: var(--black);
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--bsh);
  transition: var(--transition) background-color;
  z-index: 25;
}
.swiper-button-prev::after, .swiper-button-next::after {
  content: "";
}
.swiper-button-prev svg, .swiper-button-next svg {
  fill: var(--black);
  transition: var(--transition) fill;
}
@media (any-hover: hover) {
  .swiper-button-prev:hover, .swiper-button-next:hover {
    background-color: var(--accent);
  }
  .swiper-button-prev:hover svg, .swiper-button-next:hover svg {
    fill: var(--white);
  }
}
.swiper-button-prev {
  left: -1.5625rem;
}
.swiper-button-prev svg {
  width: 1.9375rem;
  height: 2.625rem;
}
.swiper-button-next {
  right: -1.5625rem;
}
.swiper-button-next svg {
  width: 0.75rem;
  height: 1.625rem;
}

.about-section {
  padding: 1.875rem 0;
  background-color: var(--gray-3);
}
.about-section__grid {
  display: grid;
  gap: 0.6875rem 2.0625rem;
}
@media (min-width: 1025px) {
  .about-section__grid {
    grid-template-columns: calc(555 / var(--container-width) * 100%) calc(584 / var(--container-width) * 100%);
    grid-template-areas: "picture title" "picture text" "picture ." "features features";
  }
}
.about-section__picture {
  aspect-ratio: 555/350;
}
@media (min-width: 1025px) {
  .about-section__picture {
    grid-area: picture;
  }
}
@media (min-width: 1025px) {
  .about-section__title {
    grid-area: title;
  }
}
.about-section__text {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .about-section__text {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) {
  .about-section__text {
    grid-area: text;
  }
}
.about-section__items {
  margin-top: 1.5rem;
}
@media (min-width: 1025px) {
  .about-section__items {
    grid-area: features;
  }
}

.accordion-item__arrow {
  grid-column: -2/-1;
  position: relative;
  width: 1.9375rem;
  height: 1.9375rem;
  border-radius: 50%;
  background-color: var(--accent);
  transition: var(--transition) transform;
}
.accordion-item__arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.9375rem;
  height: 0.9375rem;
  background-image: url(../images/icons/arrow-down.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.accordion-item__title {
  font-size: 1.5rem;
  line-height: 1.2083333333;
  font-weight: 600;
}
@media (max-width: 767px) {
  .accordion-item__title {
    font-size: 1.25rem;
  }
}
.accordion-item__subtitle {
  grid-column: 1/2;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--gray-2);
}
@media (max-width: 767px) {
  .accordion-item__subtitle {
    font-size: 1rem;
  }
}
.accordion-item__subtitle + .accordion-item__arrow {
  align-self: end;
}
.accordion-item__toggle {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.9375rem;
  align-items: center;
  border-bottom: 1px dashed var(--gray-2);
}
@media (min-width: 1025px) {
  .accordion-item__toggle {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
}
.accordion-item__toggle[aria-expanded=true] .accordion-item__arrow {
  transform: rotate(180deg);
}
.accordion-item__content {
  max-height: 0;
  overflow: hidden;
  transition: var(--transition) max-height;
}
@media (min-width: 1025px) {
  .accordion-item__content {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
}
.accordion-item__content ul {
  margin-left: 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style: disc;
}
@media (max-width: 767px) {
  .accordion-item__content ul {
    font-size: 1rem;
  }
}
.accordion-item__content ul:not(:first-child) {
  margin-top: 1rem;
}
.accordion-item__row {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  display: grid;
  gap: 2.25rem 3rem;
}
@media (min-width: 768px) {
  .accordion-item__row {
    grid-template-columns: repeat(2, 1fr);
  }
}
.accordion-item__col {
  display: grid;
  row-gap: 2.25rem;
}

.benefits {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.benefits__row {
  display: grid;
  gap: 1.25rem;
}
@media (min-width: 1025px) {
  .benefits__row {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
.benefits__img {
  aspect-ratio: 551/597;
}
.benefits__title {
  margin-bottom: 2.75rem;
}
.benefits__items {
  display: grid;
  gap: 2.75rem 2.25rem;
}
@media (min-width: 768px) {
  .benefits__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
.benefits__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 1201px) {
  .benefits__item {
    max-width: 15.4375rem;
  }
}
@media (max-width: 767px) {
  .benefits__item {
    align-items: center;
    text-align: center;
  }
}
.benefits__item-img {
  margin-bottom: 0.6875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 50%;
  background-color: var(--black);
  font-size: 2rem;
  line-height: 1.21875;
  font-weight: 300;
  color: var(--white);
}
.benefits__item-title {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}
.benefits__item-text {
  font-size: 1rem;
  line-height: 1.5;
}

.blog-article {
  position: relative;
}
.blog-article__img {
  display: block;
  aspect-ratio: 262/174;
}
.blog-article__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: grid;
  row-gap: 1.0625rem;
  padding: 0.875rem 0 0 0;
}
.blog-article__date {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: var(--gray-2);
}
.blog-article__title {
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 600;
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .blog-article__title:hover {
    color: var(--accent);
  }
}
.blog-article__text {
  font-size: 0.875rem;
  line-height: 1.2857142857;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.375rem, 1fr));
  gap: 1.875rem;
}

.breadcrumbs {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}
.breadcrumbs__item {
  font-family: var(--inter-font);
  font-size: 0.75rem;
  line-height: 1.25;
}
.breadcrumbs__item:not(:last-child) {
  position: relative;
  padding-right: 1.3125rem;
}
.breadcrumbs__item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 0.6875rem;
  height: 0.6875rem;
  background-image: url(../images/icons/crumb-arrow.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.breadcrumbs__item:last-child {
  color: var(--gray-2);
}
.breadcrumbs__link {
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .breadcrumbs__link:hover {
    color: var(--accent);
  }
}

.burger-btn {
  display: none;
  background-color: transparent;
  border: none;
  flex-shrink: 0;
  width: 1.875rem;
  height: 1.6875rem;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .burger-btn {
    display: flex;
  }
}
.burger-btn span {
  width: 100%;
  height: 0.1875rem;
  background-color: var(--white);
}

.burger-menu {
  display: none;
}
@media (max-width: 1024px) {
  .burger-menu {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 101;
    width: 100%;
    height: 100%;
    padding: 4.0625rem 0.9375rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background-color: var(--dark);
    color: var(--white);
    font-size: 1.125rem;
    line-height: 1.2222222222;
    transition: var(--transition) left;
    overflow-y: auto;
  }
}
@media (max-width: 767px) {
  .burger-menu {
    font-size: 0.875rem;
    line-height: 1.2142857143;
  }
}
@media (max-width: 1024px) {
  .burger-menu.active {
    left: 0;
  }
}
.burger-menu__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem;
  background-color: transparent;
  border: none;
  font-size: 2.8125rem;
  line-height: 57%;
  color: var(--white);
}
.burger-menu__email {
  color: var(--white);
}
.burger-menu__nav {
  flex-grow: 1;
}
.burger-menu__nav .menu__list {
  flex-direction: column;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .burger-menu__nav .menu__link {
    font-size: 1.5rem;
    line-height: 1.2083333333;
  }
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.checkbox__input {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}
.checkbox__input:checked ~ .checkbox__style:before {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.checkbox__style {
  position: relative;
  flex-shrink: 0;
  margin-right: 0.6875rem;
  width: 1.125rem;
  height: 1.125rem;
  border: 1px solid var(--white);
}
.checkbox__style:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.8125rem;
  height: 0.625rem;
  background-image: url(../images/icons/check.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition) all;
}
.checkbox__text a {
  text-decoration: underline;
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .checkbox__text a:hover {
    color: var(--accent);
  }
}

.contacts {
  position: relative;
  padding: 0.9375rem 0 1.875rem;
  display: grid;
  align-items: flex-start;
  gap: 0.9375rem 1.875rem;
}
@media (min-width: 768px) {
  .contacts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1025px) {
  .contacts {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 12.8125rem;
  }
}
.contacts::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100vw;
  background-color: var(--gray-3);
}
.contacts__item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  gap: 1.25rem 1.0625rem;
}
.contacts__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6875rem;
  height: 2.6875rem;
  border-radius: 50%;
  background-color: var(--white);
  align-self: start;
  grid-row: 1/-1;
}
.contacts__item-icon img {
  width: 0.9375rem;
  height: 0.9375rem;
}
.contacts__item-title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}
.contacts__item-content {
  display: grid;
  row-gap: 0.375rem;
  grid-column: 2/3;
  font-size: 1rem;
  line-height: 1.5;
}
.contacts__item-phones {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
}
.contacts__item-messanger {
  width: 1rem;
  height: 0.9375rem;
}

.map {
  height: 24.375rem;
  border-radius: 0.625rem;
}
@media (max-width: 767px) {
  .map {
    height: 15.625rem;
  }
}

@media (min-width: 1025px) {
  #map {
    margin-top: -9.6875rem;
  }
}

.contacts-page {
  padding-bottom: 5.625rem;
}
@media (max-width: 1024px) {
  .contacts-page {
    padding-bottom: 1.875rem;
  }
}

.cooperation {
  position: relative;
  padding: 1.375rem 1.25rem 1.5625rem 4.125rem;
  background-color: var(--white);
  box-shadow: var(--bsh-2);
}
@media (max-width: 767px) {
  .cooperation {
    padding: 1.5625rem 1.25rem;
  }
}
.cooperation::before {
  content: "";
  position: absolute;
  top: 3.625rem;
  left: -2rem;
  width: 3.8125rem;
  height: 3.8125rem;
  background-image: url(../images/icons/clip.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.cooperation__row {
  display: grid;
  gap: 1.875rem;
}
@media (min-width: 1025px) {
  .cooperation__row {
    grid-template-columns: 40.1746724891% 1fr 41.2227074236%;
    grid-template-areas: "content . form";
  }
}
.cooperation__content {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .cooperation__content {
    font-size: 1rem;
  }
}
@media (min-width: 1025px) {
  .cooperation__content {
    grid-area: content;
  }
}
.cooperation__content p:first-child {
  font-weight: 600;
}
.cooperation__content p:not(:last-child) {
  margin-bottom: 1.25rem;
}
.cooperation__form {
  padding: 2.3125rem 0.9375rem 3.3125rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--black);
  color: var(--white);
}
@media (min-width: 1025px) {
  .cooperation__form {
    grid-area: form;
  }
}
@media (min-width: 768px) {
  .cooperation__form-body {
    width: 22.1875rem;
    max-width: 100%;
  }
}
.cooperation__title {
  margin-bottom: 1.625rem;
  text-align: center;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 25;
  min-width: 9.625rem;
  background-color: var(--white);
  box-shadow: var(--bsh);
  border-radius: var(--brs-xs);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.dropdown__list li:first-child .dropdown__link {
  border-radius: var(--brs-xs) var(--brs-xs) 0 0;
}
.dropdown__list li:last-child .dropdown__link {
  border-radius: 0 0 var(--brs-xs) var(--brs-xs);
}
.dropdown__link {
  display: flex;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.9285714286;
  color: var(--black);
  transition: var(--transition) background-color;
}
@media (max-width: 500px) {
  .dropdown__link {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
}
@media (any-hover: hover) {
  .dropdown__link:hover {
    background-color: var(--gray-3);
    color: var(--black);
  }
}
.dropdown__phones li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.dropdown__phone {
  font-size: 1rem;
  line-height: 2.125;
  font-weight: 500;
  color: var(--black);
}
.dropdown__messanger {
  aspect-ratio: 1/1;
  width: 1.125rem;
  height: 1.0625rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19.6875rem, 1fr));
  gap: 1.25rem;
  align-items: flex-start;
}
@media (max-width: 500px) {
  .features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.125rem, 1fr));
    gap: 1.25rem;
  }
}

.feature {
  display: grid;
  row-gap: 0.5625rem;
  justify-items: center;
}
@media (max-width: 767px) {
  .feature {
    text-align: center;
  }
}
.feature__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  background-color: var(--gray);
}
.feature__img img {
  width: 4rem;
  height: 4rem;
}
.feature__text {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 19.6875rem;
}

.form__agree, .form__required {
  font-size: 0.875rem;
  line-height: 1.2142857143;
}
@media (max-width: 500px) {
  .form__agree, .form__required {
    font-size: 0.6875rem;
  }
}

.feedback-form {
  display: grid;
  gap: 1.25rem;
}
@media (min-width: 768px) {
  .feedback-form {
    grid-template-columns: 1fr auto;
    grid-template-areas: "fields fields" "agree submit" "required submit";
  }
}
.feedback-form__fields {
  display: grid;
  gap: 1rem 1.75rem;
}
@media (min-width: 768px) {
  .feedback-form__fields {
    grid-template-columns: repeat(2, 1fr);
    grid-area: fields;
  }
}
@media (min-width: 768px) {
  .feedback-form__agree {
    grid-area: agree;
  }
}
@media (min-width: 768px) {
  .feedback-form__required {
    grid-area: required;
  }
}
.feedback-form__btn {
  padding: 0.71875rem 2.765625rem;
  font-size: 1.125rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .feedback-form__btn {
    grid-area: submit;
    align-self: start;
  }
}
@media (min-width: 768px) {
  .feedback-form--orientation_column {
    grid-template-columns: unset;
    grid-template-areas: unset;
  }
}
@media (min-width: 768px) {
  .feedback-form--orientation_column .feedback-form__fields {
    grid-template-columns: unset;
    grid-area: unset;
  }
}
@media (min-width: 768px) {
  .feedback-form--orientation_column .feedback-form__required {
    grid-area: unset;
  }
}
.feedback-form--orientation_column .feedback-form__btn {
  padding-right: 1.8125rem;
  padding-left: 1.8125rem;
  justify-self: center;
}
@media (min-width: 768px) {
  .feedback-form--orientation_column .feedback-form__fields, .feedback-form--orientation_column .feedback-form__required, .feedback-form--orientation_column .feedback-form__agree, .feedback-form--orientation_column .feedback-form__btn {
    grid-area: unset;
  }
}

.feedback {
  padding: 2.6875rem 0 5.1875rem;
  min-height: 25.1875rem;
  background-color: var(--black);
  color: var(--white);
  background-image: url(../images/content/form-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .feedback {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
}
@media (min-width: 1025px) {
  .feedback__form {
    width: 45.3125rem;
    margin: 0 auto;
  }
}
.feedback__title {
  text-align: center;
  margin-bottom: 2.625rem;
}
@media (max-width: 767px) {
  .feedback__title {
    margin-bottom: 1.5625rem;
  }
}

.input {
  display: flex;
  align-items: center;
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
  width: 100%;
  height: 3.125rem;
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: var(--brs);
  font-size: 1rem;
  line-height: 1.25;
}
.input::placeholder {
  color: var(--gray-4);
}
.input:focus {
  border-color: var(--accent);
}

.footer__top {
  padding: 1rem 0 0.375rem;
  background-color: var(--dark);
  color: var(--white);
}
@media (max-width: 1024px) {
  .footer__top {
    padding-bottom: 1rem;
  }
}
.footer__top-inner {
  display: grid;
  gap: 1rem;
}
@media (min-width: 1025px) {
  .footer__top-inner {
    align-items: center;
    grid-template-columns: calc(282 / var(--container-width) * 100%) 1fr;
    grid-template-areas: "logo menu";
  }
}
.footer__logo .logo__img {
  display: block;
  width: 14.9375rem;
  height: 6.25rem;
}
@media (min-width: 1025px) {
  .footer__logo {
    grid-area: logo;
  }
}
@media (min-width: 1025px) {
  .footer__menu {
    grid-area: menu;
  }
}
.footer__main {
  padding: 1.375rem 0 1.625rem;
  background-color: var(--gray);
}
.footer__main-inner {
  display: grid;
  gap: 1rem 5.1666666667%;
}
@media (min-width: 501px) {
  .footer__main-inner {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
  }
}
@media (min-width: 1025px) {
  .footer__main-inner {
    column-gap: 5.1666666667%;
    grid-template-columns: repeat(2, 1fr) repeat(2, minmax(18.75%, auto));
  }
}
@media (min-width: 501px) {
  .footer__main-inner {
    grid-template-areas: "contacts call" "menu-1 menu-2" "copy developer";
  }
}
@media (min-width: 1025px) {
  .footer__main-inner {
    grid-template-areas: "contacts call menu-1 menu-2" "copy . . developer";
  }
}
.footer__col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 501px) {
  .footer__col--contacts {
    grid-area: contacts;
  }
}
@media (min-width: 501px) {
  .footer__col--call {
    grid-area: call;
  }
}
.footer__title {
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.footer__title, .footer__link {
  font-size: 0.875rem;
  line-height: 2.2142857143;
}
@media (min-width: 768px) {
  .footer__address {
    max-width: 16rem;
  }
}
.footer__address, .footer__schedule, .footer__email, .footer__contact {
  font-size: 0.875rem;
  line-height: 1.2142857143;
}
.footer__schedule {
  margin-bottom: 0.1875rem;
}
.footer__schedule > div {
  display: flex;
  gap: 2px;
}
.footer__schedule dt {
  font-weight: 500;
}
.footer__contacts {
  display: grid;
  row-gap: 0.5rem;
  margin-bottom: 1.375rem;
}
.footer__contact {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
}
.footer__messanger {
  width: 1.0625rem;
  height: 1.0625rem;
}
.footer__btn {
  padding: 0.71875rem 1.125rem;
}
@media (min-width: 501px) {
  .footer__menu-1 {
    grid-area: menu-1;
  }
}
@media (min-width: 501px) {
  .footer__menu-2 {
    grid-area: menu-2;
  }
}
.footer__copyright {
  font-size: 0.875rem;
  line-height: 1.7857142857;
}
@media (min-width: 501px) {
  .footer__copyright {
    grid-area: copy;
  }
}
.footer__developer {
  display: flex;
  align-items: center;
}
@media (min-width: 501px) {
  .footer__developer {
    grid-area: developer;
  }
}
.footer__developer-logo {
  flex-shrink: 0;
  margin-right: 0.3125rem;
  width: 0.9375rem;
  height: 0.9375rem;
  background-color: #8ABE2E;
}
.footer__developer-text {
  font-size: 0.75rem;
  line-height: 1.6666666667;
}
.footer__developer-text a {
  text-decoration: underline;
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .footer__developer-text a:hover {
    color: var(--accent);
  }
}

.header {
  position: relative;
  background-color: var(--dark);
  color: var(--white);
}
.header__address, .header__schedule, .header__email {
  font-size: 0.875rem;
  line-height: 1.2142857143;
}
.header__email {
  display: none;
}
@media (min-width: 1025px) {
  .header__email {
    display: block;
    text-decoration: underline;
    grid-area: email;
  }
}
@media (min-width: 1201px) {
  .header__email {
    justify-self: center;
  }
}
.header__messanger {
  width: 1.625rem;
  height: 1.5625rem;
}
.header__address {
  display: none;
}
@media (min-width: 1025px) {
  .header__address {
    display: block;
    grid-area: address;
  }
}
.header__schedule {
  display: none;
}
@media (min-width: 1025px) {
  .header__schedule {
    display: block;
    grid-area: schedule;
  }
}
.header__contacts {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
@media (min-width: 1025px) {
  .header__contacts {
    grid-area: contacts;
    justify-self: end;
  }
}
@media (max-width: 1024px) {
  .header__contacts {
    margin-left: auto;
  }
}
@media (max-width: 767px) {
  .header__contacts {
    margin-left: auto;
  }
}
.header__contacts:hover .header__contacts-dropdown {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.header__contacts-drop {
  position: relative;
}
.header__contacts-drop svg {
  width: 0.6875rem;
  height: 0.6875rem;
  fill: var(--white);
  transition: var(--transition) fill;
}
@media (any-hover: hover) {
  .header__contacts-drop:hover svg {
    fill: var(--accent);
  }
}
.header__contacts-dropdown {
  left: auto;
  right: 0;
  width: max-content;
  padding: 0.375rem 1.5625rem 0.5625rem 2.0625rem;
}
@media (max-width: 500px) {
  .header__contacts-dropdown {
    right: -500%;
  }
}
.header__phone {
  display: none;
}
@media (min-width: 768px) {
  .header__phone {
    display: block;
    font-size: 1.125rem;
    line-height: 2.1111111111;
    font-weight: 600;
  }
}
.header__main {
  padding-top: 0.8125rem;
}
@media (min-width: 1025px) {
  .header__main {
    display: grid;
    gap: 0.5rem;
    align-items: center;
    grid-template-columns: 13.4375rem 1fr auto;
    grid-template-areas: "address address schedule" "email contacts contacts" "logo menu search";
  }
}
@media (min-width: 1201px) {
  .header__main {
    grid-template-columns: 13.4375rem auto 1fr 1fr 1fr auto;
    grid-template-areas: "address address email schedule contacts contacts" "logo menu menu menu menu search";
  }
}
@media (max-width: 1024px) {
  .header__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.125rem;
    padding: 0.9375rem 0;
  }
}
@media (max-width: 767px) {
  .header__main {
    gap: 0.625rem;
  }
}
.header__menu {
  display: none;
}
@media (min-width: 1025px) {
  .header__menu {
    display: block;
    flex-grow: 1;
    grid-area: menu;
  }
}
.header__logo {
  flex-shrink: 0 0 13.4375rem;
  aspect-ratio: 215/90;
}
@media (max-width: 767px) {
  .header__logo {
    flex-shrink: 0 0 5.625rem;
  }
}
@media (min-width: 1025px) {
  .header__logo {
    grid-area: logo;
  }
}
.header__search {
  flex-shrink: 0;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__search svg {
  width: 1.8125rem;
  height: 1.8125rem;
  fill: var(--white);
}
@media (any-hover: hover) {
  .header__search:hover svg {
    fill: var(--accent);
  }
}

.history-block {
  padding: 3.4375rem 0 4.375rem;
  display: grid;
  gap: 1.875rem;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .history-block {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1025px) {
  .history-block {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1201px) {
  .history-block {
    align-items: stretch;
    gap: 0;
    justify-items: center;
    grid-template-columns: 8.125rem repeat(5, 1fr) 8.125rem;
    grid-template-areas: "item-1 item-1 item-3 item-3 item-5 item-5 ." "line line line line line line line" ". item-2 item-2 item-4 item-4 item-6 item-6";
  }
}
@media (min-width: 1201px) {
  .history-block__item {
    position: relative;
  }
}
@media (min-width: 1201px) {
  .history-block__item::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1.4375rem;
    height: 8.125rem;
    background-image: url(../images/icons/line-dot.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 1201px) {
  .history-block__item--1 {
    grid-area: item-1;
  }
  .history-block__item--1 > * {
    max-width: 12.9375rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item--2 {
    grid-area: item-2;
  }
  .history-block__item--2 > * {
    max-width: 18rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item--3 {
    grid-area: item-3;
  }
  .history-block__item--3 > * {
    max-width: 12.8125rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item--4 {
    grid-area: item-4;
  }
  .history-block__item--4 > * {
    max-width: 14.625rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item--5 {
    grid-area: item-5;
  }
  .history-block__item--5 > * {
    max-width: 15.0625rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item--6 {
    grid-area: item-6;
  }
  .history-block__item--6 > * {
    max-width: 14.1875rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item:nth-child(odd) {
    padding-bottom: 7.5rem;
  }
  .history-block__item:nth-child(odd)::before {
    bottom: -0.6875rem;
  }
}
@media (min-width: 1201px) {
  .history-block__item:nth-child(even) {
    align-self: start;
    padding-top: 7.5rem;
  }
  .history-block__item:nth-child(even)::before {
    top: -0.6875rem;
    transform: rotate(180deg);
  }
}
.history-block__line {
  display: none;
}
@media (min-width: 1201px) {
  .history-block__line {
    display: block;
    grid-area: line;
    padding-right: 1rem;
    padding-left: 1rem;
    justify-self: stretch;
    position: relative;
    grid-area: line;
    height: 1px;
    border-bottom: 1px dashed var(--black);
  }
  .history-block__line::before, .history-block__line::after {
    content: "";
    position: absolute;
    top: -3px;
    width: 0.4375rem;
    height: 0.4375rem;
    border-radius: 50%;
    background-color: var(--black);
  }
  .history-block__line::before {
    left: 0;
  }
  .history-block__line::after {
    right: 0;
  }
}

.history-item {
  display: grid;
  row-gap: 0.375rem;
}
.history-item__year {
  font-family: var(--barlow-font);
  font-size: 4rem;
  line-height: 1.203125;
  font-weight: 600;
  color: var(--gray-2);
}
.history-item__text {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 300;
}

.img-cover, .img-contain {
  width: 100%;
  height: 100%;
}
.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}

.logo {
  display: block;
}
.logo__img {
  object-fit: contain;
}

.menu__list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1025px) {
  .menu__list {
    justify-content: space-between;
  }
}
.menu__item {
  position: relative;
  display: flex;
  align-items: center;
}
.menu__item svg {
  width: 0.6875rem;
  height: 0.6875rem;
  fill: var(--white);
}
.menu__item:hover .menu__link {
  color: var(--white);
}
.menu__item:hover .menu__link .btn__text::after {
  width: 100%;
}
.menu__item:hover .dropdown {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.menu__link {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
  padding: 0.625rem;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  color: var(--white);
}
.menu__link .btn__text {
  position: relative;
}
.menu__link .btn__text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: var(--accent);
  transition: var(--transition) width;
}

.not-found-block {
  padding-top: 10rem;
  padding-bottom: 10rem;
  color: var(--white);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .not-found-block {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}
.not-found-block__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.not-found-block__suptitle {
  font-size: 2rem;
  line-height: 1.21875;
  font-weight: 600;
}
@media (max-width: 500px) {
  .not-found-block__suptitle {
    font-size: 1.25rem;
  }
}
.not-found-block__wrap {
  display: flex;
  flex-direction: column;
  max-width: 35.9375rem;
  align-items: center;
  text-align: center;
}
.not-found-block__title {
  font-size: 15.625rem;
  line-height: 1;
  font-weight: 400;
}
@media (max-width: 767px) {
  .not-found-block__title {
    font-size: 6.25rem;
  }
}
.not-found-block__subtitle {
  margin-bottom: 1.875rem;
  font-size: 1.25rem;
  line-height: 1.2;
}
@media (max-width: 500px) {
  .not-found-block__subtitle {
    font-size: 1rem;
  }
}
.not-found-block__btn {
  padding: 0.75rem 2.5rem;
}
@media (min-width: 768px) {
  .not-found-block__btn {
    align-self: end;
  }
}

.pagination {
  padding: 4.1875rem 0 5.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__list {
  display: flex;
}
.pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  transition: var(--transition) background-color, var(--transition) color;
}
.pagination__link.active {
  background-color: var(--black);
  color: var(--white);
}
@media (any-hover: hover) {
  .pagination__link:not(.active):hover {
    color: var(--accent);
  }
}
.pagination__prev, .pagination__next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
}
.pagination__prev svg, .pagination__next svg {
  width: 2.0625rem;
  height: 2.0625rem;
  fill: var(--black);
  transition: var(--transition) fill;
}
@media (any-hover: hover) {
  .pagination__prev:hover svg, .pagination__next:hover svg {
    fill: var(--accent);
  }
}

/* .popup {
    position: absolute;
} */
.promo {
  padding: 4.5rem 0 1.875rem;
  background-size: 0 0;
}
@media (max-width: 767px) {
  .promo {
    padding-top: 1.875rem;
  }
}
@media (min-width: 768px) {
  .promo {
    min-height: 35.4375rem;
    background-position: 115% 65%;
    background-size: 69.9479166667% auto;
    background-repeat: no-repeat;
  }
}
@media (max-width: 1200px) {
  .promo {
    background-position-x: 150%;
  }
}
.promo__slider {
  margin-bottom: 3.125rem;
}
.promo__title {
  margin-bottom: 0.625rem;
  font-size: 2rem;
  line-height: 1.3125;
  font-weight: 600;
}
@media (max-width: 767px) {
  .promo__title {
    font-size: 1.5rem;
  }
}
.promo__text {
  font-size: 1rem;
  line-height: 1.5;
}
.promo__text:not(:last-child) {
  margin-bottom: 1.875rem;
}
@media (min-width: 768px) {
  .promo__title, .promo__text {
    max-width: 23.5rem;
  }
}
.promo__btn {
  padding: 0.75rem 2.6875rem;
}
.promo .swiper-horizontal > .swiper-pagination-bullets,
.promo .swiper-pagination-bullets.swiper-pagination-horizontal {
  position: static;
}

.promo-slider__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.radiobutton {
  /* display: flex;
  align-items: center;
  justify-content: space-between;

  &__input {
  	width: 1px;
  	height: 1px;
  	overflow: hidden;
  	position: absolute;
  	clip: rect(0 0 0 0);

  	&:checked~.radiobutton {
  		&__style {
  			&:before {
  				content: '';
  				position: absolute;
  			}
  		}
  	}
  }

  &__style {
  	position: relative;
  	flex-shrink: 0;
  }

  &__text {} */
}

.resume-block {
  position: relative;
  padding: 2.75rem 3.875rem 1.375rem;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: var(--bsh);
}
@media (max-width: 767px) {
  .resume-block {
    padding: 1.875rem 2.5rem;
  }
}
@media (min-width: 1201px) {
  .resume-block {
    max-width: 69.625rem;
    margin: 0 auto;
  }
}
.resume-block::before {
  content: "";
  position: absolute;
  top: 3.625rem;
  left: -2rem;
  width: 3.8125rem;
  height: 3.8125rem;
  background-image: url(../images/icons/clip.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.resume-block__title {
  margin-bottom: 1.0625rem;
  font-size: 1.5rem;
  line-height: 1.2083333333;
  font-weight: 600;
}
@media (max-width: 500px) {
  .resume-block__title {
    font-size: 1.25rem;
  }
}
.resume-block__email {
  margin-bottom: 2.3125rem;
  font-size: 1.5rem;
  line-height: 1.2083333333;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .resume-block__email {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }
}
@media (any-hover: hover) {
  .resume-block__email:hover {
    color: var(--accent);
  }
}
.resume-block__subtitle {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}
@media (max-width: 500px) {
  .resume-block__subtitle {
    font-size: 1rem;
  }
}
.resume-block__text {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (max-width: 500px) {
  .resume-block__text {
    font-size: 1rem;
  }
}

.reviews {
  padding: 4.75rem 0 2.25rem;
}
.reviews-slider {
  position: relative;
  padding: 1.5625rem 0 3.125rem;
}
.reviews-slider .swiper-pagination {
  bottom: 0;
  justify-content: center;
}
.reviews-slider__item {
  cursor: pointer;
  position: relative;
}
.reviews-slider__item::before, .reviews-slider__item::after {
  content: "";
  opacity: 0;
  transition: var(--transition) opacity;
}
.reviews-slider__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(78, 77, 77, 0.34);
}
.reviews-slider__item::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.75rem;
  height: 2.75rem;
  background-image: url(../images/icons/eye.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (any-hover: hover) {
  .reviews-slider__item:hover::before, .reviews-slider__item:hover::after {
    opacity: 1;
  }
}
.reviews-slider__img {
  aspect-ratio: 257/357;
}

.search-form {
  position: absolute;
  top: 100%;
  right: -100%;
  z-index: 30;
  width: 100%;
  background-color: var(--gray-3);
  transition: var(--transition) right;
}
.search-form.active {
  right: 0;
}
.search-form__row {
  display: grid;
  grid-template-columns: 1fr max-content;
}
@media (max-width: 1024px) {
  .search-form__row {
    padding-right: 0;
    padding-left: 0;
  }
}
.search-form__input {
  padding-right: 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  background-color: var(--gray-3);
}
@media (max-width: 1024px) {
  .search-form__input {
    padding-left: 0.9375rem;
  }
}
@media (max-width: 500px) {
  .search-form__input {
    font-size: 0.875rem;
    line-height: 1.2142857143;
  }
}
.search-form__btn {
  padding: 0.9375rem;
  border-radius: 0;
}

.slider-btns {
  display: none;
}
@media (min-width: 768px) {
  .slider-btns {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.slider-wrap {
  position: relative;
}

.title-container--with_bg {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: var(--gray);
}
.title-container + .text-block {
  padding-bottom: 3.125rem;
}
@media (min-width: 768px) {
  .title-container + .text-block {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.page-top {
  padding-bottom: 1.5625rem;
}

.truck-block {
  padding-bottom: 1.875rem;
}

.truck {
  position: relative;
  padding: 1.25rem 0;
}
.truck:first-child {
  padding-top: 1.875rem;
  border-top: 1px solid #DFDFDF;
}
@media (min-width: 1025px) {
  .truck::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: -1;
    width: 100vw;
    height: 55%;
    background-color: var(--gray-3);
  }
}
.truck__row {
  display: grid;
  gap: 1.875rem;
}
@media (min-width: 1025px) {
  .truck__row {
    grid-template-columns: repeat(2, 1fr);
  }
}
.truck__col {
  display: flex;
  flex-direction: column;
}
.truck__title {
  margin-bottom: 1.375rem;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}
.truck__main {
  margin-bottom: 1.0625rem;
  position: relative;
}
.truck__main span {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 0.46875rem 1.25rem;
  background-color: var(--white);
  border: 1px solid var(--accent);
  font-size: 1rem;
  line-height: 1.5;
}
.truck__main::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--accent);
}
.truck__features {
  margin-bottom: 2.5rem;
}
.truck__features-title {
  margin-bottom: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}
.truck__brand {
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: uppercase;
}
.truck__leader {
  display: flex;
}
.truck__trailer {
  position: relative;
  margin: 0.9375rem 0 1.75rem;
}
.truck__trailer span {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 0.46875rem 0.78125rem 0.46875rem;
  background-color: var(--white);
  border: 1px solid var(--gray-2);
  font-size: 1rem;
  line-height: 1.5;
}
@media (min-width: 1025px) {
  .truck__trailer span {
    background-color: var(--gray-3);
  }
}
.truck__trailer::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--gray-2);
}

.leader div {
  display: grid;
  row-gap: 1.25rem;
  justify-items: center;
  text-align: center;
}
.leader div:not(:first-child) {
  padding-left: 2.1875rem;
}
@media (max-width: 500px) {
  .leader div:not(:first-child) {
    padding-left: 0.9375rem;
  }
}
.leader div:not(:last-child) {
  padding-right: 2.1875rem;
}
@media (max-width: 500px) {
  .leader div:not(:last-child) {
    padding-right: 0.9375rem;
  }
}
.leader div + div {
  position: relative;
}
.leader div + div::before {
  content: "";
  position: absolute;
  top: -0.625rem;
  left: 0;
  width: 1px;
  height: 3.375rem;
  background-color: rgba(160, 160, 160, 0.3);
}
.leader dt {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}
@media (max-width: 500px) {
  .leader dt {
    font-size: 1rem;
  }
}
.leader dd {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (max-width: 500px) {
  .leader dd {
    font-size: 1rem;
  }
}

.vacancy-block {
  padding: 0.5rem 0 3.5625rem;
  background-color: var(--gray-3);
}
.vacancy-block__heading {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
@media (min-width: 1025px) {
  .vacancy-block__heading {
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
  }
}
.vacancy-block__title {
  position: relative;
  display: inline-block;
  padding-right: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 400;
  color: var(--gray-2);
}
.vacancy-block__title::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -8.625rem;
  width: 8.625rem;
  height: 1px;
  background-color: var(--gray);
}

.vacancies-list {
  padding-bottom: 4.625rem;
}

/* label.error {
    color: red;
}

.input.error {
    border-color: red;
} */
.why-choose-us {
  padding-bottom: 3.125rem;
}
.why-choose-us__heading {
  margin-bottom: 2.8125rem;
}

.benefits-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(21.875rem, 1fr));
  gap: 0.9375rem;
}
@media (max-width: 500px) {
  .benefits-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.125rem, 1fr));
    gap: 0.9375rem;
  }
}

.benefit-item {
  display: grid;
  row-gap: 0.625rem;
  justify-items: center;
  text-align: center;
}
.benefit-item__picture {
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.benefit-item__picture img {
  border-radius: 50%;
}
.benefit-item__text {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.why-we {
  position: relative;
  padding: 2.75rem 0 2.125rem;
}
.why-we__heading {
  margin-bottom: 2.0625rem;
}
.why-we__img {
  display: none;
}
@media (min-width: 1201px) {
  .why-we__img {
    display: block;
    position: absolute;
  }
  .why-we__img--left {
    top: 5.75rem;
    left: -3%;
    width: 24.5%;
    aspect-ratio: 470/482;
  }
  .why-we__img--right {
    right: -4%;
    bottom: 1.3125rem;
    width: 21.85%;
    aspect-ratio: 420/576;
  }
}
.why-we__list {
  display: grid;
}
@media (min-width: 501px) {
  .why-we__list {
    grid-auto-rows: minmax(18.8125rem, auto);
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1025px) {
  .why-we__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.why-we__item {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.why-we__item-num {
  font-size: 4rem;
  line-height: 1.21875;
  font-weight: 600;
  text-transform: uppercase;
}
.why-we__item-text {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}
@media (max-width: 500px) {
  .why-we__item-text {
    font-size: 1.125rem;
  }
}
.why-we__item-img {
  position: absolute;
}
.why-we__item {
  position: relative;
  padding: 0.9375rem;
}
.why-we__item--1, .why-we__item--6 {
  background-color: var(--gray);
}
.why-we__item--2, .why-we__item--7 {
  background-color: var(--dark);
  color: var(--white);
}
.why-we__item--2 .why-we__item-img {
  top: 5.25rem;
  left: 3.5rem;
  width: 10.0625rem;
  height: 10.0625rem;
}
.why-we__item--2 .why-we__item-text {
  padding-top: 8.125rem;
}
.why-we__item--3 {
  background-color: var(--gray-2);
}
@media (min-width: 501px) {
  .why-we__item--5 {
    grid-column: span 2;
  }
}
@media (max-width: 500px) {
  .why-we__item--5 {
    padding-bottom: 12.5rem;
  }
}
.why-we__item--5 .why-we__item-img {
  bottom: 2.3125rem;
  left: 0;
  width: 10.5rem;
  height: 10.5rem;
}
@media (min-width: 501px) {
  .why-we__item--5 .why-we__item-text {
    align-self: end;
    padding-left: 12.125rem;
  }
}
.why-we__item--7 {
  padding-bottom: 10.3125rem;
}
.why-we__item--7 .why-we__item-img {
  right: 1.1875rem;
  bottom: 0.125rem;
  width: 9.75rem;
  height: 9.75rem;
}
.why-we__item--7 .why-we__item-text {
  margin-top: -1.25rem;
}