.header {
	$this: &;

	position: relative;
	background-color: var(--dark);
	color: var(--white);

	// .header__address

	&__address,
	&__schedule,
	&__email {
		@include font-size(14, 17);
	}

	&__email {
		display: none;

		@include media('1025', min) {
			display: block;
			text-decoration: underline;
			grid-area: email;
		}

		@include media('1201', min) {
			justify-self: center;
		}
	}

	&__messanger {
		width: rem(26);
		height: rem(25);
	}

	&__address {
		display: none;

		@include media('1025', min) {
			display: block;
			grid-area: address;
		}
	}

	&__schedule {
		display: none;

		@include media('1025', min) {
			display: block;
			grid-area: schedule;
		}
	}

	// .header__contacts

	&__contacts {
		@include flex-center-y;
		gap: rem(10);

		@include media('1025', min) {
			grid-area: contacts;
			justify-self: end;
		}

		@include media('1024', max) {
			margin-left: auto;
		}

		@include media('767', max) {
			margin-left: auto;
		}

		&:hover {
			#{$this} {
				&__contacts-dropdown {
					@include show();
				}
			}
		}
	}

	&__contacts-drop {
		position: relative;

		svg {
			width: rem(11);
			height: rem(11);
			fill: var(--white);
			transition: var(--transition) fill;
		}

		@media (any-hover: hover) {
			&:hover {
				svg {
					fill: var(--accent);
				}
			}
		}
	}

	&__contacts-dropdown {
		left: auto;
		right: 0;
		width: max-content;
		padding: rem(6) rem(25) rem(9) rem(33);

		@include media('500', max) {
			right: -500%;
		}
	}

	&__phone {
		display: none;

		@include media('768', min) {
			display: block;
			@include font-size(18, 38);
			font-weight: 600;
		}
	}

	// .header__main

	&__main {
		padding-top: rem(13);

		@include media('1025', min) {
			display: grid;
			gap: rem(8);
			align-items: center;
			grid-template-columns: rem(215) 1fr auto;
			grid-template-areas:
				'address address schedule'
				'email contacts contacts'
				'logo menu search'
			;
		}

		@include media('1201', min) {
			grid-template-columns: rem(215) auto 1fr 1fr 1fr auto;
			grid-template-areas:
				'address address email schedule contacts contacts'
				'logo menu menu menu menu search'
			;
		}

		@include media('1024', max) {
			@include flex-center-between;
			gap: rem(18);
			padding: rem(15) 0;
		}

		@include media('767', max) {
			gap: rem(10);
		}
	}

	&__menu {
		display: none;

		@include media('1025', min) {
			display: block;
			flex-grow: 1;
			grid-area: menu;
		}
	}

	&__logo {
		flex-shrink: 0 0 rem(215);
		aspect-ratio: 215 / 90;

		@include media('767', max) {
			flex-shrink: 0 0 rem(90);
		}

		@include media('1025', min) {
			grid-area: logo;
		}
	}

	&__search {
		flex-shrink: 0;
		padding: rem(10);
		@include flex-center;

		svg {
			width: rem(29);
			height: rem(29);
			fill: var(--white);
		}

		@media (any-hover: hover) {
			&:hover {
				svg {
					fill: var(--accent);
				}
			}
		}
	}
}
