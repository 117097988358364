.breadcrumbs {
    @include padding-y(rem(14));
    
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
    }

	&__item {
        font-family: var(--inter-font);
        @include font-size(12, 15);

		&:not(:last-child) {
            position: relative;
            padding-right: rem(21);

			&::after {
				content: '';
                @include position-center-y;
                right: 0;
                width: rem(11);
                height: rem(11);
                background-image: url(../images/icons/crumb-arrow.svg);
                @include bg(contain);
			}
		}

        &:last-child {
            color: var(--gray-2);
        }
	}

    &__link {
        transition: var(--transition) color;

        @media (any-hover: hover) {
            &:hover {
                color: var(--accent);
            }
        }
    }
}
