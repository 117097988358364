.container {
    --width: var(--container-width);

	width: calc(var(--width) + var(--container-padding) * 2);
	max-width: 100%;
	@include padding-x(var(--container-padding));
	margin: 0 auto;

    &--fluid {
        --width: var(--container-width_xl);
    }

    > .text-block {
        &:last-child {
            padding-bottom: rem(45);
        }
    }
}

.wrapper {
	position: relative;
	@include flex-column;
	min-height: 100vh;
	overflow-x: hidden;
}

.page {
	position: relative;
	flex: 1 0 auto;
}
