.burger-menu {
	display: none;

	@include media('1024', max) {
		display: block;
		position: fixed;
		top: 0;
		left: -100%;
		z-index: 101;
		width: 100%;
		height: 100%;
		padding: rem(65) rem(15);
		@include flex-column;
		gap: rem(20);
		background-color: var(--dark);
		color: var(--white);
		@include font-size(18, 22);
		transition: var(--transition) left;
		overflow-y: auto;
	}

	@include media('767', max) {
		@include font-size(14, 17);
	}

	&.active {
		@include media('1024', max) {
			left: 0;
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: rem(20);
		background-color: transparent;
		border: none;
		font-size: rem(45);
		line-height: 57%;
		color: var(--white);
	}

	&__email {
		color: var(--white);
	}

	&__nav {
		flex-grow: 1;

		.menu {
			&__list {
				flex-direction: column;
				flex-wrap: nowrap;
			}

			&__link {
				@include media('768', min) {
					@include font-size(24, 29);
				}
			}
		}
	}
}
