.menu {
	&__list {
		display: flex;
		flex-wrap: wrap;

		@include media('1025', min) {
			justify-content: space-between;
		}
	}

	&__item {
		position: relative;
        @include flex-center-y;

        svg {
			width: rem(11);
			height: rem(11);
			fill: var(--white);
		}

		&:hover {
            .menu__link {
                color: var(--white);

				.btn__text::after {
					width: 100%;
				}
            }

			.dropdown {
				@include show();
			}
		}
	}

	&__link {
		@include flex-center-y;
		gap: rem(7);
		padding: rem(10);
		@include font-size(18, 22);
		color: var(--white);

		.btn__text {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0%;
				height: 1px;
				background-color: var(--accent);
				transition: var(--transition) width;
			}
		}
	}
}
