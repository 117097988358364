.promo {
	padding: rem(72) 0 rem(30);
	background-size: 0 0;

	@include media('767', max) {
		padding-top: rem(30);
	}

	@include media('768', min) {
		min-height: rem(567);
		background-position: 115% 65%;
		background-size: calc(1343 / 1920 * 100%) auto;
		background-repeat: no-repeat;
	}

	@include media('1200', max) {
		background-position-x: 150%;
	}

	&__slider {
		margin-bottom: rem(50);
	}

	// .promo__title

	&__title {
		margin-bottom: rem(10);
		@include font-size(32, 42);
		font-weight: 600;

		@include media('767', max) {
			font-size: rem(24);
		}
	}

	// .promo__text

	&__text {
		@include font-size(16, 24);

		&:not(:last-child) {
			margin-bottom: rem(30);
		}
	}

	&__title,
	&__text {
		@include media('768', min) {
			max-width: rem(376);
		}
	}

	&__btn {
		padding: rem(12) rem(43);
	}

    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        position: static;
    }
}

.promo-slider {
	&__item {
		@include flex-column-start;
	}
}
