.input {
	@include flex-center-y;
	@include padding-x(rem(25));
	width: 100%;
	height: rem(50);
	background-color: var(--white);
	border: 1px solid var(--gray);
	border-radius: var(--brs);
	@include font-size(16, 20);

	&::placeholder {
		color: var(--gray-4);
	}

	&:focus {
		border-color: var(--accent);
	}
}
