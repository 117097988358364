.btn {
	$this: &;

	padding: 0;
	cursor: pointer;
	border: none;
	font-family: var(--main-font);
	transition: var(--transition) color, var(--transition) background-color, var(--transition) box-shadow;
	color: currentColor;

	&.disabled,
	&[disabled] {
        background-color: var(--gray);
		border: none;
		cursor: not-allowed;

		@media (any-hover: hover) {
			&:hover {
				color: var(--white);
			}
		}
	}

	svg,
	.icon,
	&__icon {
		flex-shrink: 0;
		transition: var(--transition) fill;
	}

	&--style {
		&_bg {

			&-black,
			&-accent {
				@include font-size(18, 27);
				border-radius: var(--brs);
				color: var(--white);
			}

			&-black {
				background-color: var(--black);

				@media (any-hover: hover) {
					&:hover {
						background-color: var(--accent);
					}
				}
			}

			&-accent {
				background-color: var(--accent);

                @media (any-hover: hover) {
                    &:hover {
                        background-color: var(--white);
                        color: var(--black);
                    }
                }
			}
		}

		&_messanger {
			display: inline-block;
			border-radius: 50%;
			transition: var(--transition) transform;

			@media (any-hover: hover) {
				&:hover {
					transform: translateY(-3px);
				}
			}
		}

		&_link {
			@media (any-hover: hover) {
				&:hover {
					color: var(--accent);
				}
			}
		}
	}

	&--transparent {
		background-color: transparent;

		@media (any-hover: hover) {
			&:hover {
				background-color: transparent;
			}
		}
	}
}
