:root {
	--white: #fff;
	--black: #000;
	--accent: #E0A100;
	--dark: #272425;
	--gray: #D9D9D9;
	--gray-2: #A0A0A0;
	--gray-3: #F5F5F5;
	--gray-4: #757575;

    --container-width: #{rem(1140)};
	--container-width_xl: #{rem(1200)};
	--container-padding: #{rem(15)};

	--base-font-size: 16;
	--main-font: 'Montserrat', sans-serif;
    --inter-font: 'Inter', sans-serif;
    --barlow-font: 'Barlow', sans-serif;
	--color-default: var(--black);

	--transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
	--duration300ms: 0.3s;
	--duration500ms: 0.5s;
	--duration1s: 1s;
	--delay: var(--duration300ms);
	--transition: var(--duration300ms) var(--transition-timing);
	--transition-long: var(--duration1s) var(--transition-timing);

	--brs: 25px;
	--brs-xs: 3px;
	--bsh: 0 4px 15px rgba(0, 0, 0, 0.15);
    --bsh-2: 0 4px 25px rgba(0, 0, 0, 0.1);
}
