.why-choose-us {
	padding-bottom: rem(50);
	// .why-choose-us__heading

	&__heading {
		margin-bottom: rem(45);
	}
}

.benefits-list {
	@include grid-auto-fit(rem(350), rem(15));

	@include media('500', max) {
		@include grid-auto-fit(rem(290), rem(15));
	}
}

.benefit-item {
	@include row-grid(rem(10));
	justify-items: center;
	text-align: center;

	&__picture {
		aspect-ratio: 1 / 1;
		border-radius: 50%;

		img {
			border-radius: 50%;
		}
	}

	// .benefit-item__text

	&__text {
		@include font-size(16, 24);
		font-weight: 500;
	}
}
