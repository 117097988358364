.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
	display: flex;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 rem(14);
	width: rem(14);
	height: rem(14);
	border: 1px solid #B2BBC0;
	background-color: transparent;
	opacity: 1;
	transition: var(--transition) background-color,
		var(--transition) border-color;

	@media (any-hover: hover) {
		&:hover {
			background-color: #B2BBC0;
		}
	}
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
	background-color: var(--black);
	border-color: var(--black);
}

.swiper-button {
    &-prev,
    &-next {
        @include position-center-y;
        @include round(rem(50));
        @include flex-center;
        box-shadow: var(--bsh);
        transition: var(--transition) background-color;
        z-index: 25;

        &::after {
            content: '';
        }

        svg {
            fill: var(--black);
            transition: var(--transition) fill;
        }
        
        @media (any-hover: hover) {
            &:hover {
                background-color: var(--accent);

                svg {
                    fill: var(--white);
                }
            }
        }
    }

    &-prev {
        left: rem(-25);

        svg {
            width: rem(31);
            height: rem(42);
        }
    }

    &-next {
        right: rem(-25);

        svg {
            width: rem(12);
            height: rem(26);
        }
    }
}